import { YupTypes } from '@/models/ValidationModel';
import {
  createValidationObjectSchema,
} from '@/utils/validations';

export const StartupGuideSchema = createValidationObjectSchema({
  icon: {
    required: true,
  },
  title: {
    required: true,
  },
  subtitle: {
    required: true,
  },
  code: {
    required: true,
  }
});
