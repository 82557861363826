
	import { defineComponent } from 'vue';
	import { bootstrapIcons } from '@/utils/icons';

	export default defineComponent({
		props: {
			modelValue: { default: () => {
				return null as unknown as string
			} },
			placeholder: { default: 'Select an icon' },
			label: { default: 'Icon' },
			required: { default: false },
			showLabel: { default: true },
			name: { default: null as unknown as string }
		},
		emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
			getIcon(){
				return `<span :class="${ this.modelValue }"></span>`
			}
    },
		data(){
			return {
				icons: bootstrapIcons,
				show: false
			}
		},
		methods: {
			toggleDropdown(){
				this.show = !this.show

				if (this.show) {
					this.$nextTick(() => {
						(this.$refs.drowpdowncontainer as any).focus();
					});
				}
			},
			hideDropdown(event) {
				if (!event.currentTarget.contains(event.relatedTarget)) {
					this.show = false;
				}
			},
			setValue(value) {
				this.value = value;
			}
		}
	});
